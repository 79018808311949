@import url("./utils.css");
@import url("./font.css");
@import url("./variables.css");
@import url("./animations.css");

html,
body,
#app {
    width: 100%;
    height: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px !important;
    color: var(--color-text);
}
