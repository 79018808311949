.nothing-found {
    width: 100%;
    height: auto;
    padding: 1.5rem 0;
}

.nothing-found img {
    width: min(100%, calc(500px + 4rem));
    margin-bottom: 0.5rem;
}
