.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--color-primary);
}

.ant-steps-item-finish .ant-steps-item-icon {
    border-color: var(--color-primary);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: none;
    border-color: none;
}

@media screen and (max-width: 768px) {
    .ant-steps-item-icon {
        margin-right: 28px !important;
    }
}

.ant-steps-item-custom.ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon {
    color: var(--color-primary);
}

.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    background-color: var(--color-primary);
}
