button {
    padding: 1rem 2rem;
    font-weight: 700;
    border: 0;
    border-radius: 2rem;
    cursor: pointer;
}

.button-primary {
    background-color: var(--color-primary);
    color: white;
    text-transform: uppercase;
}
