header {
    background-color: white;
}

.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid var(--color-grey-light);
}

.header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__inner--slim {
    height: auto;
    padding: 1rem 0rem;
}

.header__inner--tall {
    height: 104px;
    padding: 2rem 0rem;
}

.header__icon {
    height: 2rem;
}

.header__brand {
    font-size: 1.333rem;
    font-weight: 600;
    color: var(--color-text);
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.header__brand:hover {
    color: var(--color-text);
}
