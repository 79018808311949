.feed-entry {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid var(--color-grey-light);
    display: flex;
    gap: 1rem;
    animation: var(--animation-fade-in);
}

.feed-entry .text-small {
    color: var(--color-grey-middle);
}

.feed-entry:last-child {
    border-bottom: none;
    padding-bottom: 2rem;
}

.feed-entry__avatar {
    min-width: 3.5rem;
    width: 3.5rem;
    min-height: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
}

.feed-entry__contact-name {
    font-weight: 600;
}

.feed-entry header {
    height: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.375rem;
}

.feed-entry__right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 0.25rem;
}

.feed-entry__img {
    border-radius: 0.5rem;
    object-fit: cover;
    width: 400px;
    max-width: 100%;
}

.feed-entry__img--skeleton {
    width: clamp(400px, 400px, 100%) !important;
    border-radius: 0.5rem !important;
    aspect-ratio: 1 / 1;
}
