html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

.visible-tablet {
  display: none;
}

@media screen and (max-width: 480px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hidden-tablet {
    display: none;
  }

  .visible-tablet {
    display: inherit;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0 0 .5em;
}

h1 {
  font-size: 4.209rem;
}

h2 {
  font-size: 3.157rem;
}

h3 {
  font-size: 2.369rem;
}

h4 {
  font-size: 1.777rem;
}

h5 {
  font-size: 1.333rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.5;
}

.text-small {
  font-size: .875rem;
}

.text-very-small {
  font-size: .75rem;
}

.text-very-very-small {
  font-size: .563rem;
}

.text-bold {
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3.157rem;
  }

  h2 {
    font-size: 2.369rem;
  }

  h3 {
    font-size: 1.777rem;
  }

  h4 {
    font-size: 1.333rem;
  }

  h5 {
    font-size: 1rem;
  }
}

:root {
  --color-primary: #725dfd;
  --color-text: #000000d9;
  --color-grey-very-light: #fafafa;
  --color-grey-light: #ebebeb;
  --color-grey-middle: #00000073;
  --animation-fade-in: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html, body, #app {
  width: 100%;
  height: 100%;
}

body {
  color: var(--color-text);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 16px !important;
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  z-index: 1;
  border-radius: .25rem;
  line-height: 1;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.react-loading-skeleton:after {
  content: " ";
  display: var(--pseudo-element-display);
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient( 90deg, var(--base-color), var(--highlight-color), var(--base-color) );
  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

.home {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

button {
  cursor: pointer;
  border: 0;
  border-radius: 2rem;
  padding: 1rem 2rem;
  font-weight: 700;
}

.button-primary {
  background-color: var(--color-primary);
  color: #fff;
  text-transform: uppercase;
}

header {
  background-color: #fff;
}

.header--fixed {
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid var(--color-grey-light);
  position: fixed;
  top: 0;
  left: 0;
}

.header__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__inner--slim {
  height: auto;
  padding: 1rem 0;
}

.header__inner--tall {
  height: 104px;
  padding: 2rem 0;
}

.header__icon {
  height: 2rem;
}

.header__brand {
  color: var(--color-text);
  align-items: center;
  gap: .25rem;
  font-size: 1.333rem;
  font-weight: 600;
  display: flex;
}

.header__brand:hover {
  color: var(--color-text);
}

.container {
  height: auto;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
}

@media (max-width: 1280px) {
  .container {
    max-width: 992px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 768px;
  }
}

.jumbotron-home {
  flex-grow: 1;
}

.jumbotron-home .container {
  height: 100%;
}

.jumbotron-home__inner {
  height: 100%;
  gap: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

@media screen and (max-width: 992px) {
  .jumbotron-home__inner {
    flex-direction: column;
    padding-bottom: 2rem;
  }
}

.jumbotron-home__left {
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
  display: flex;
}

.jumbotron-home__left__button-container {
  margin: 1rem 0;
}

.jumbotron-home__right {
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 448px) and (max-height: 768px) {
  .jumbotron-home__right {
    display: none;
  }
}

.jumbotron-home__right img {
  width: 80%;
}

footer {
  background-color: var(--color-grey-very-light);
  padding: 2rem 0;
}

.feed {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.feed-grid {
  grid-template-columns: 4fr 2fr;
  grid-template-areas: "left right";
  align-items: start;
  gap: 2rem;
  padding-top: 65px;
  display: grid;
}

@media screen and (max-width: 992px) {
  .feed-grid {
    grid-template-columns: 3fr 2fr;
  }
}

@media screen and (max-width: 768px) {
  .feed-grid {
    grid-auto-rows: unset;
    grid-template-areas: "right right"
                         "left left";
    gap: 0;
  }
}

.feed-grid__left {
  grid-area: left;
}

.feed-grid__right {
  grid-area: right;
  position: sticky;
  top: 65px;
}

@media screen and (max-width: 768px) {
  .feed-grid__right {
    position: static;
  }
}

.feed-entries {
  padding-top: .5rem;
}

@media screen and (max-width: 768px) {
  .feed-entries {
    padding-top: 0;
  }
}

.feed-entry {
  width: 100%;
  border-bottom: 1px solid var(--color-grey-light);
  animation: var(--animation-fade-in);
  gap: 1rem;
  padding: 1rem 0;
  display: flex;
}

.feed-entry .text-small {
  color: var(--color-grey-middle);
}

.feed-entry:last-child {
  border-bottom: none;
  padding-bottom: 2rem;
}

.feed-entry__avatar {
  min-width: 3.5rem;
  width: 3.5rem;
  min-height: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.feed-entry__contact-name {
  font-weight: 600;
}

.feed-entry header {
  height: 3.5rem;
  flex-direction: column;
  justify-content: center;
  gap: .375rem;
  display: flex;
}

.feed-entry__right {
  flex-direction: column;
  flex-grow: 1;
  gap: .25rem;
  display: flex;
}

.feed-entry__img {
  object-fit: cover;
  width: 400px;
  max-width: 100%;
  border-radius: .5rem;
}

.feed-entry__img--skeleton {
  aspect-ratio: 1 / 1;
  width: min(400px, 100%) !important;
  border-radius: .5rem !important;
}

.nothing-found {
  width: 100%;
  height: auto;
  padding: 1.5rem 0;
}

.nothing-found img {
  width: min(100%, 500px + 4rem);
  margin-bottom: .5rem;
}

.progress {
  padding-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .progress {
    border-bottom: 1px solid #ececf3;
    padding: 1.5rem 0 .5rem .75rem;
  }
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-primary);
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--color-primary);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  border-color: none;
  background: none;
}

@media screen and (max-width: 768px) {
  .ant-steps-item-icon {
    margin-right: 28px !important;
  }
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-primary);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: var(--color-primary);
}

.qrcode {
  animation: var(--animation-fade-in);
  padding: 1.5rem 0;
}

.qrcode__skeleton {
  aspect-ratio: 1 / 1;
  width: min(100%, 400px + 4rem) !important;
}

.qrcode ol {
  margin-top: .5rem;
}

/*# sourceMappingURL=index.f93cf53d.css.map */
