.qrcode {
    padding: 1.5rem 0;
    animation: var(--animation-fade-in);
}

.qrcode__skeleton {
    width: min(100%, calc(400px + 4rem)) !important;
    aspect-ratio: 1/1;
}

.qrcode ol {
    margin-top: 0.5rem;
}
