.jumbotron-home {
    flex-grow: 1;
}

.jumbotron-home .container {
    height: 100%;
}

.jumbotron-home__inner {
    height: 100%;
    display: flex;
    padding-bottom: 2rem;
    gap: 2rem;
}

@media screen and (max-width: 992px) {
    .jumbotron-home__inner {
        flex-direction: column;
        padding-bottom: 2rem;
    }
}

.jumbotron-home__left {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jumbotron-home__left__button-container {
    margin: 1rem 0;
}

.jumbotron-home__right {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 448px) and (max-height: 768px) {
    .jumbotron-home__right {
        display: none;
    }
}

.jumbotron-home__right img {
    width: 80%;
}
