h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 0 0.5em 0;
}

h1 {
    font-size: 4.209rem;
}

h2 {
    font-size: 3.157rem;
}

h3 {
    font-size: 2.369rem;
}

h4 {
    font-size: 1.777rem;
}

h5 {
    font-size: 1.333rem;
}

h6 {
    font-size: 1rem;
}

p {
    font-size: 1.25rem;
    line-height: 1.5;
}

.text-small {
    font-size: 0.875rem;
}

.text-very-small {
    font-size: 0.75rem;
}

.text-very-very-small {
    font-size: 0.563rem;
}

.text-bold {
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 3.157rem;
    }

    h2 {
        font-size: 2.369rem;
    }

    h3 {
        font-size: 1.777rem;
    }

    h4 {
        font-size: 1.333rem;
    }

    h5 {
        font-size: 1rem;
    }
}
