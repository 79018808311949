.feed {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.feed-grid {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-template-areas: "left right";
    align-items: start;
    gap: 2rem;
    padding-top: 65px;
}

@media screen and (max-width: 992px) {
    .feed-grid {
        grid-template-columns: 3fr 2fr;
    }
}

@media screen and (max-width: 768px) {
    .feed-grid {
        grid-auto-rows: unset;
        grid-template-areas:
            "right right"
            "left left";
        gap: 0;
    }
}

.feed-grid__left {
    grid-area: left;
}

.feed-grid__right {
    grid-area: right;
    position: sticky;
    top: 65px;
}

@media screen and (max-width: 768px) {
    .feed-grid__right {
        position: static;
    }
}
