.progress {
    padding-top: 1.5rem;
}

@media screen and (max-width: 768px) {
    .progress {
        border-bottom: 1px solid #ececf3;
        padding: 1.5rem 0 0.5rem 0.75rem;
    }
}
